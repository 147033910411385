{
  "formErrors": "{num} hibát találtunk a következőkben:",
  "leadTemplates": {
    "title": "Lead sablonok",
    "newLeadTemplate": "Új lead sablon",
    "addLeadFromTemplate": "Lead létrehozása sablonból",
    "saveAsTemplate": "Mentés sablonként",
    "editLeadTemplate": "Lead sablon szerkesztése",
    "deleteLeadTemplate": "Lead sablon törlése",
    "deleteFailed": "Lead sablon törlése sikertelen",
    "deletedSuccess": "Lead sablon sikeresen törölve",
    "updateFailed": "Lead sablon frissítése sikertelen",
    "createFailed": "Lead sablon létrehozása sikertelen",
    "createdSuccess": "Lead sablon sikeresen létrehozva",
    "name": "Lead sablon neve"
  },
  "sampleExcel": "Minta fejléccel ellátott Excel fájl: ",
  "roles": {
    "title": "Jogosultságok",
    "updatedSuccess": "Jogosultságok sikeresen frissítve",
    "updateFailed": "Jogosultságok frissítése sikertelen",
    "createdSuccess": "Szerepkör sikeresen létrehozva",
    "createFailed": "Szerepkör létrehozása sikertelen",
    "name": "Név",
    "create" : "Létrehozás",
    "permissions": "Jogosultságok"
  },
  "bubbles": {
    "businessGoal": "Ezt a mezőt mindenképp töltsd ki, mert így tudod nyomon követni az üzleti célodat. Üzleti cél meghatározásához segédletet a weboldalról tudsz letölteni!",
    "editBusinessGoal": "Év közben, ha már pontosabban látod az üzleti célodat, bármikor tudod módosítani a megadott értéket, hogy a valós célt mutassa. ",
    "businessGoalProgress": "A %-os érték mutatja, hogy hol tartasz az üzleti célod megvalósításában az InnoLeadsben rögzített szerződésbe fordult leadek és a folyamatban lévő leadek alapján. Ez utóbbi értékét a valószínűséggel korrigáltuk. A csúszka 1% teljesítési érték felett mutat elmozdulást. ",
    "todayTasks": "Napi teendőidről minden reggel 6:00-kor emlékeztető e-mailt küldünk ezzel is támogatva a munkádat.",
    "partnersSearch": "Bármely rögzített értékre tudsz keresni. Akár az utolsó kapcsolatfelvétel időpontjára, a megjelölt hónapra.",
    "partnersLastContactedAt": "Azért érdemes beírni az utolsó kapcsolatfelvétel időpontját, mert ha pl.: azt határozod meg magadnak, hogy félévente minden partnereddel legalább egyszer akarsz beszélni, akkor a keresőben rá tudsz szűrni a dátumra. pl.: augusztus (08.). A rendszer kidobja a 08. hónapban utoljára kontakttól partnereket. És már tudod is hívni őket vagy e-mailt küldeni számukra.",
    "partnersEmail": "A megadott e-mail címre kattintva azonnal üzenetet tudsz küldeni a partnerednek. ",
    "taskComplete": "Amikor a teendő készen van, a kis négyzetet pipáld be. Az elkészült feladatok közé fog átkerülni, bármikor visszahozható az aktív teendők közé. ",
    "profileReferral": "Ezt a linket küldd el a partnerednek. Amennyiben ő ezen a linken keresztül regisztrál, úgy mi azt látni fogjuk és jelezzük a számodra, hogy milyen értékben és mikor tudsz számlát kiállítani nekünk.",
    "leadBusinessGoal": "A képlet a leszerződött üzleteid értékeit arányosítja az üzleti célértékhez.",
    "topLeads": "A lead várható bevételét súlyoztuk az üzlet megkötési valószínűségével, ez alapján állítottuk fel a sorrendet. A legnagyobb leadekre érdemes kiemelt figyelmet fordítani. ",
    "topPartners": "Egy partner értékét a partner nevéhez kapcsolt leszerződött projektek értéke és a partnerhez kapcsolt leadek valószínűséggel súlyozott várható összebevétele adja. A legnagyobb partnerekre érdemes kiemelt figyelmet fordítani. ",
    "leadNotes": "Ha a leadet ajánláson keresztül kaptad és/vagy ehhez üzleti megállapodás is kötődik, akkor itt érdemes rögzíteni. ",
    "leadProbabilityPercent": "Az üzletkötés valószínűségét írd be ide százalékosan, ezzel a súlyszámmal fogjuk korrigálni a lead várható bevételét és ezzel számoljuk az üzleti tervedbe (a valószínűség értéke is módosítható) ",
    "leadProbabilityGoal": "Ez a mutató a valószínűségekkel korrigált előkészített üzleteid értékét arányosítja a még le nem szerződött üzleti célértéked összegéhez. Ezt a mutatót azért érdemes figyelni, mert itt látszik, hogy még mennyi üzletet kell előkészítened és leszerződnöd, hogy teljesüljön az üzleti célod. ",
    "leadMenuItem": "Üzleti lehetőség, előkészítés alatt lévő üzlet",
    "leadCreate": "Hozz létre egy mérföldkövet (pl ajánlat kiküldése) és ezt követően tudsz a leadhez kapcsolódóan teendőket létrehozni + - hozz létre teendőket a mérföldkőhöz kapcsolódóan annak érdekében, hogy a rendszer jól tudja támogatni a munkádat.",
    "leadStatus": "A lead státuszát itt tudod beállítani. A „Leszerződve” státusz nem választható, egy technikai sor. A leadet a Lead megtekintés oldalon zárd le, ha létrejött a szerződés. "
  },
  "validation": {
    "number": "Számot adj meg",
    "required": "Kötelező mező",
    "probability_percent": {
      "greater_than_or_equal_to": "A valószínűség nem lehet 0-nál kisebb.",
      "less_than_or_equal_to": "A valószínűség nem lehet 100-nál nagyobb.",
      "required": "A valószínűség megadása kötelező."
    },
    "goal": {
      "greater_than_or_equal_to": "A érték nem lehet 0-nál kisebb.",
      "required": "Az érték megadása kötelező."
    },
    "net_value": {
      "greater_than_or_equal_to": "A várható bevétel nem lehet 0-nál kisebb."
    },
    "closed_net_value": {
      "greater_than_or_equal_to": "A leszerződött nem lehet 0-nál kisebb."
    },
    "email": {
      "invalid": "Érvénytelen e-mail cím"
    },
    "first_name": {
      "length": "A keresztnévnek legalább 2 karakter hosszúnak kell lennie."
    },
    "last_name": {
      "length": "A vezetéknévnek legalább 2 karakter hosszúnak kell lennie."
    }
  },
  "auth": {
    "success": "Sikeres bejelentkezés!"
  },
  "businessGoals": {
    "goal": "Üzleti cél",
    "emptyGoal": "Nincs megadva üzleti cél",
    "title": "Idei év üzleti célja",
    "editGoal": "Cél szerkesztése",
    "createdSuccess": "Az üzleti cél sikeresen létrehozva!",
    "contractedGoal": "Megvalósított üzleti cél",
    "probabilityGoal": "Előkészített üzletek értéke"
  },
  "days": {
    "monday": "H",
    "tuesday": "K",
    "wednesday": "SZ",
    "thursday": "CS",
    "friday": "F",
    "saturday": "SZ",
    "sunday": "V"
  },
  "dashboard": {
    "noTask": "Erre a napra nincs feladat!"
  },
  "months": {
    "january": "Január",
    "february": "Február",
    "march": "Március",
    "april": "Április",
    "may": "Május",
    "june": "Június",
    "july": "Július",
    "august": "Augusztus",
    "september": "Szeptember",
    "october": "Október",
    "november": "November",
    "december": "December"
  },
  "priorities": {
    "3": "Magas",
    "2": "Közepes",
    "1": "Alacsony"
  },
  "users": {
    "createdSuccess": "A felhasználó sikeresen létrehozva!",
    "updatedSuccess": "A felhasználó sikeresen frissítve!",
    "deletedSuccess": "A felhasználó sikeresen törölve!",
    "createFailed": "A felhasználó létrehozása sikertelen!",
    "updateFailed": "A felhasználó frissítése sikertelen!",
    "deleteFailed": "A felhasználó törlése sikertelen!",
    "title": "Felhasználók",
    "name": "Név",
    "email": "E-mail",
    "password": "Jelszó",
    "role": "Szerepkör"
  },
  "todos": {
    "creator": "Létrehozó",
    "completedSuccess": "A feladat sikeresen elvégezve!",
    "completedFailed": "A feladat elvégzése sikertelen!",
    "deletedSuccess": "A feladat sikeresen törölve!",
    "createdSuccess": "A feladat sikeresen létrehozva!",
    "updatedSuccess": "A feladat sikeresen frissítve!",
    "deleteFailed": "A feladat törlése sikertelen!",
    "updateFailed": "A feladat frissítése sikertelen!",
    "createFailed": "A feladat létrehozása sikertelen!",
    "todayTodos": "Mai teendők",
    "myTodos": "Teendőim",
    "dueDate": "Határidő",
    "addTodo": "Új teendő",
    "editTodo": "Teendő szerkesztése",
    "priority": "Prioritás",
    "active": "Elkészült",
    "inactive": "Elvégzendő",
    "completedAt": "Elkészült",
    "titleRequired": "A teendő címe kötelező",
    "completed": "Elkészült feladatok",
    "complete": "Feladat elvégezve",
    "delete": "Feladat törlése",
    "export": "Riport",
    "redirectQuestion": "Meg szeretnéd tekinteni a feladathoz kapcsolódó leadet?",
    "send_event_invitation": "Naptár bejegyzés",
    "event_start_date": "Esemény kezdete",
    "event_end_date": "Esemény vége",
    "meeting_link": "Meeting link",
    "startDateRequired": "Kezdő dátum megadása kötelező",
    "endDateRequired": "Befejező dátum megadása kötelező"
  },
  "subscriptions": {
    "title": "Előfizetések",
    "plans": "Csomagok",
    "active": "Már van aktív előfizetés a céghez. Az előfizetés kezeléséhez menj a profilodba.",
    "editSubscription": "Előfizetés szerkesztése",
    "attention": "Fizess elő most, hogy hozzáférhess a kizárólagos tartalmakhoz és ajánlatokhoz!",
    "goToSubscriptions": "Tovább a csomagokhoz"
  },
  "leads": {
    "closedSuccess": "A lead sikeresen lezárva!",
    "createFailed": "A lead létrehozása sikertelen!",
    "createdSuccess": "A lead sikeresen létrehozva!",
    "updateFailed": "A lead frissítése sikertelen!",
    "closeFailed": "A lead lezárása sikertelen!",
    "deletedSuccess": "A lead sikeresen törölve!",
    "deleteFailed": "A lead törlése sikertelen!",
    "addLead": "Új lead",
    "topLeads": "Legnagyobb lead-ek",
    "topPartners": "Legnagyobb partner-ek",
    "editLead": "Lead szerkesztése",
    "deleteLead": "Lead törlése",
    "title": "Lead megnevezése",
    "created_at": "Létrehozás dátuma",
    "created_by": "létrehozta",
    "description": "Leírás",
    "dueDate": "Várható szerződéskötés",
    "probabilityPercent": "Valószínűség",
    "budget": "Költségvetés",
    "netValue": "Várható bevétel",
    "currentLeads": "Jelenlegi leadek",
    "status": "Státusz",
    "partner": "Partner",
    "details": "Részletek",
    "deleteActivity": "Mérföldkő törlése",
    "createActivity": "Mérföldkő létrehozása",
    "notes": "Ajánlási kondiciók",
    "closeLead": "Lead lezárása",
    "finalPrice": "Leszerződött összeg",
    "closedBy": "Lezárta",
    "export": "Riport"
  },
  "activities": {
    "addActivity": "Új mérföldkő hozzáadása",
    "editActivity": "Mérföldkő szerkesztése",
    "deleteActivity": "Mérföldkő törlése",
    "deleteSuccess": "A mérföldkő sikeresen törölve!",
    "title": "Cím",
    "description": "Leírás",
    "activityTitle": "Mérföldkövek",
    "assignees": "Felelősök",
    "dueDate": "Határidő",
    "createFailed": "A mérföldkő létrehozása sikertelen!",
    "deleteFailed": "A mérföldkő törlése sikertelen!"
  },
  "greetings": {
    "morning": "Jó reggelt",
    "afternoon": "Jó napot",
    "evening": "Jó estét"
  },
  "profile": {
    "company_website": "Cég weboldala",
    "company_name": "Cégnév",
    "newEmail": "Új e-mail cím",
    "confirmPassword": "Jelszó megerősítése",
    "currentPassword": "Jelenlegi jelszó",
    "newPassword": "Új jelszó",
    "newPasswordConfirm": "Új jelszó megerősítése",
    "create": "Létrehozás",
    "edit": "Szerkesztés",
    "fullName": "Teljes név",
    "firstName": "Keresztnév",
    "lastName": "Vezetéknév",
    "email": "E-mail",
    "companyName": "Cégnév",
    "phone": "Telefon",
    "taxNumber": "Adószám",
    "contactName": "Kapcsolattartó neve",
    "companySite": "Cég weboldala",
    "notes": "Megjegyzések",
    "index": "Profilom",
    "referrals": "Ajánlások",
    "referralLink": "Ajánló link",
    "copyLink": "Link másolása",
    "profileDetails": "Profil részletei",
    "profilePicture": "Profilkép",
    "profilePictureDesc": "Engedélyezett formátumok: JPG, PNG,",
    "signInMethod": "Bejelentkezési mód",
    "deactivateAccount": "Fiók deaktiválása",
    "deactivateAccountNoticeTitle": "Figyelem!",
    "deactivateAccountNoticeDesc": "Az adatai végleg törlődnek a rendszerünkből. Ezt a folyamatot nem lehet visszaállítani.",
    "deactivateAccountConfirmCheckBox": "Megerősítem a fiókom deaktiválását",
    "goToProfile": "Tovább a profilomhoz",
    "qrcode": "QR kód",
    "hideReader": "Mégsem",
    "showReader": "QR kód beolvasása",
    "areYouSureYouWantToDelete": "Ha biztosan törölni szeretnéd, kattints a Mentés gombra.",
    "saved": "Minden módosítás mentve.",
    "saving": "Módosítások mentése...",
    "api_keys": "API Kulcsok",
    "generate_api_key" : "Api kulcs generálása a következőnek",
    "generate_api_key_if_not_owner" : "Api kulcs generálása",
    "generate" : "Generálás",
    "date" : "Dátum",
    "user" : "Fehasználó",
    "api_key" : "API Kulcs",
    "tenant" : "Cég azonosító",
    "tenant_poppover" : "A cég azonosítója, amelyhez az API kulcs tartozik",
    "copy": "Másolás",
    "open_document_page": "Api dokumentum megnyitása",
    "open_document_page_info": "InnoLeads API dokumentáció megtekintése"
  },
  "general": {
    "save": "Mentés",
    "show": "Megtekintés",
    "cancel": "Mégsem",
    "change": "Módosítás",
    "update": "Frissítés",
    "delete": "Törlés",
    "edit": "Szerkesztés",
    "add": "Hozzáadás",
    "back": "Vissza",
    "next": "Következő",
    "previous": "Előző",
    "remove": "Eltávolítás",
    "search": "Keresés",
    "searchPlaceholder": "Keresés...",
    "discard": "Mégse",
    "areYouSure": "Biztos vagy benne?",
    "yes": "Igen",
    "no": "Nem",
    "deleted": "Sikeresen törölve",
    "title": "Cím",
    "description": "Leírás",
    "total": "Összesen",
    "choose": "Válassz",
    "details": "Részletek",
    "can_not_delete_yourself": "Nem törölheted magad",
    "completed": "Elkészült",
    "noData": "Nincs megjeleníthető adat"
  },
  "menu": {
    "title": "Menü",
    "dashboard": "Vezérlőpult",
    "sales": "Értékesítés",
    "leads": "Leadek",
    "leadTemplates": "Lead sablonok",
    "todos": "Teendők",
    "administration": "Adminisztráció",
    "appManagement": "Alkalmazás kezelése"
  },
  "partners": {
    "createdSuccess": "Partner sikeresen létrehozva",
    "updatedSuccess": "Partner sikeresen frissítve",
    "deletedSuccess": "Partner sikeresen törölve",
    "createFailed": "Partner létrehozása sikertelen",
    "updateFailed": "Partner frissítése sikertelen",
    "deleteFailed": "Partner törlése sikertelen",
    "title": "Partnerek",
    "name": "Név",
    "email": "E-mail",
    "last_contacted_at": "Utolsó kapcsolatfelvétel",
    "contact_channel": "Kapcsolatfelvétel módja",
    "create": "Létrehozás",
    "edit": "Szerkesztés",
    "fullName": "Teljes név",
    "firstName": "Keresztnév",
    "lastName": "Vezetéknév",
    "companyName": "Cégnév",
    "phone": "Telefon",
    "netValue": "Partner érték",
    "taxNumber": "Adószám",
    "contactName": "Kapcsolattartó neve",
    "companySite": "Cég weboldala",
    "notes": "Megjegyzések",
    "index": "Profilom",
    "firstNameRequired": "Keresztnév megadása kötelező",
    "lastNameRequired": "Vezetéknév megadása kötelező",
    "addPartner": "Új Partner",
    "todos": "Partnerrel kapcsolatos teendők",
    "import": "Importálás",
    "export": "Riport",
    "file": "Excel fájl (xlsx)",
    "import_success": "Sikeres importálás",
    "created_by": "Létrehozta"
  },
  "company": {
    "details": "Cég részletei",
    "plan": "Előfizetés",
    "billingHistory": "Számlázási előzmények",
    "permissions": "Jogosultságok",
    "activeSubscription": "Aktív előfizetés",
    "success": "Cég jogosultságai sikeresen frissítve"
  },
  "list": "Listázása",
  "partnerManagement": "Partnerkezelés",
  "companies": "Cégek",
  "create": "Létrehozás",
  "search": "Keresés",
  "nameRequired": "Név megadása kötelező",
  "titleRequired": "Megnevezés megadása kötelező",
  "fileRequired": "Fájl megadása kötelező",
  "emailRequired": "E-mail cím megadása kötelező",
  "phoneRequired": "Telefonszám megadása kötelező",
  "companyNameRequired": "Cégnév megadása kötelező",
  "taxNumberRequired": "Adószám megadása kötelező",
  "privacyPolicyRequired": "Adatvédelmi nyilatkozat elfogadása kötelező",
  "termsAndConditionsRequired": "Általános szerződési feltételek elfogadása kötelező",
  "passwordRequired": "Jelszó megadása kötelező",
  "passwordConfirmationRequired": "Jelszó megerősítése kötelező",
  "passwordMinLength": "A jelszónak legalább 8 karakter hosszúnak kell lennie",
  "passwordMatch": "A jelszavaknak egyezniük kell",
  "forgotPassword": "Elfelejtetted a jelszavad?",
  "notAMember": "Nincs még fiókod?",
  "alreadyHaveAnAccount": "Van már fiókja?",
  "signIn": "Bejelentkezés",
  "resetPassword": "Jelszó visszaállítása",
  "passwordAgain": "Jelszó újra",
  "myProfile": "Profilom",
  "myProjects": "Projektjeim",
  "mySubscription": "Előfizetésem",
  "language": "Nyelv",
  "accountSettings": "Fiók beállítások",
  "logout": "Kijelentkezés",
  "signUp": "Regisztráció",
  "companyName": "Cégnév",
  "created_at": "Létrehozva",
  "actions": "Műveletek",
  "notification": "Értesítés",
  "help": "Segítség",
  "taxNumber": "Adószám",
  "crm": "CRM",
  "name": "Név",
  "password": "Jelszó",
  "contactName": "Kapcsolattartó neve",
  "email": "Email",
  "pleaseWait": "Kérlek várj...",
  "privacyPolicy1": "Az",
  "privacyPolicy2": "adatkezelési tájékoztatást",
  "privacyPolicy3": "elolvastam, tudomásul vettem, az abban foglaltakat megértettem és elfogadom.*",
  "terms1": "A",
  "terms2": "felhasználási feltételeket",
  "terms3": "elolvastam, tudomásul vettem, az abban foglaltakat megértettem és elfogadom.*",
  "passwordHint": "Használjon 8 vagy több karaktert betűk, számok és szimbólumok keverékével.",
  "hungarian": "Magyar",
  "english": "Angol",
  "cancel_at": "Előfizetés vége",
  "subscription_handling": "Előfizetés kezelése",
  "status": "Státusz",
  "restorePassword": "Jelszó visszaállítása",
  "backToLogin": "Vissza",
  "probability_percentRequired": "Valószínűség megadása kötelező",
  "phone": "Telefonszám",
  "profile_picture": "Profilkép"
}
