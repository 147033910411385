{
  "formErrors": "There were {num} errors with your submission",
  "leadTemplates": {
    "title": "Lead Templates",
    "newLeadTemplate": "New Lead Template",
    "addLeadFromTemplate": "Add Lead From Template",
    "saveAsTemplate": "Save As Template",
    "editLeadTemplate": "Edit Lead Template",
    "deleteLeadTemplate": "Delete Lead Template",
    "deleteFailed": "Lead template delete failed",
    "deletedSuccess": "Lead template deleted successfully",
    "updateFailed": "Lead template update failed",
    "createFailed": "Lead template create failed",
    "createdSuccess": "Lead template created successfully",
    "name": "Lead template name"
  },
  "sampleExcel": "Sample Excel file with header: ",
  "roles": {
    "title": "Roles and Permissions",
    "updatedSuccess": "Role updated successfully",
    "updateFailed": "Role update failed",
    "createdSuccess": "Role created successfully",
    "createFailed": "Role creation failed",
    "name": "Name",
    "create": "Create",
    "permissions": "Permissions"
  },
  "businessGoals": {
    "goal": "Business Goal",
    "emptyGoal": "No business goal",
    "title": "Current year business goals",
    "editGoal": "Edit business goal",
    "createdSuccess": "Business goal created successfully",
    "contractedGoal": "Contracted goal",
    "probabilityGoal": "Probability goal"
  },
  "days": {
    "monday": "M",
    "tuesday": "T",
    "wednesday": "W",
    "thursday": "T",
    "friday": "F",
    "saturday": "Sa",
    "sunday": "S"
  },
  "dashboard": {
    "noTask": "No task for this day!"
  },
  "months": {
    "january": "January",
    "february": "February",
    "march": "March",
    "april": "April",
    "may": "May",
    "june": "June",
    "july": "July",
    "august": "August",
    "september": "September",
    "october": "October",
    "november": "November",
    "december": "December"
  },
  "priorities": {
    "3": "High",
    "2": "Medium",
    "1": "Low"
  },
  "users": {
    "createdSuccess": "User created successfully",
    "updatedSuccess": "User updated successfully",
    "deletedSuccess": "User deleted successfully",
    "createFailed": "User creation failed",
    "updateFailed": "User update failed",
    "deleteFailed": "User delete failed",
    "title": "Users",
    "name": "Name",
    "email": "Email",
    "password": "Password",
    "role": "Role"
  },
  "todos": {
    "creator": "Creator",
    "completedSuccess": "Task completed successfully",
    "deletedSuccess": "Task deleted successfully",
    "createdSuccess": "Task created successfully",
    "updatedSuccess": "Task updated successfully",
    "deleteFailed": "Task delete failed",
    "updateFailed": "Task update failed",
    "createFailed": "Task create failed",
    "todayTodos": "Today's Todos",
    "myTodos": "My Todos",
    "dueDate": "Due Date",
    "addTodo": "Add Todo",
    "editTodo": "Edit Todo",
    "active": "Completed",
    "inactive": "Not Completed",
    "completedAt": "Completed At",
    "priority": "Priority",
    "titleRequired": "Title is required",
    "completed": "Completed tasks",
    "delete": "Delete task",
    "send_event_invitation": "Calendar entry",
    "event_start_date": "Event start date",
    "event_end_date": "Event end date",
    "meeting_link": "Meeting link",
    "startDateRequired": "Start date is required",
    "endDateRequired": "End date is required",
    "redirectQuestion": "Do you want to redirect to the lead page?",
    "export": "Export"
  },
  "subscriptions": {
    "title": "Subscription",
    "plans": "Plans",
    "active": "You already have an active subscription to the company. To manage your subscription, go to your profile.",
    "editSubscription": "Edit Subscription",
    "attention": "Sign up now to get access to exclusive content and offers!",
    "goToSubscriptions": "Go to the Plans"
  },
  "leads": {
    "createFailed": "Lead creation failed",
    "createdSuccess": "Lead created successfully",
    "updateFailed": "Lead update failed",
    "closeFailed": "Lead close failed",
    "deletedSuccess": "Lead deleted successfully",
    "deleteFailed": "Lead delete failed",
    "addLead": "Add Lead",
    "topLeads": "Top Leads",
    "closeSuccess": "Lead closed successfully",
    "topPartners": "Top Partners",
    "editLead": "Edit Lead",
    "deleteLead": "Delete Lead",
    "title": "Title",
    "created_at": "Created At",
    "created_by": "Created By",
    "description": "Description",
    "dueDate": "Due Date",
    "probabilityPercent": "Probability",
    "budget": "Budget",
    "netValue": "Expected income",
    "currentLeads": "Current Leads",
    "status": "Status",
    "partner": "Partner",
    "details": "Details",
    "deleteActivity": "Delete milestone",
    "createActivity": "Create milestone",
    "notes": "Offer conditions",
    "closeLead": "Close Lead",
    "finalPrice": "Contracted amount",
    "closedBy": "Closed By",
    "export": "Export"
  },
  "activities": {
    "addActivity": "Add Milestone",
    "editActivity": "Edit Milestone",
    "deleteActivity": "Delete Milestone",
    "title": "Title",
    "description": "Description",
    "activityTitle": "Milestones",
    "dueDate": "Due Date",
    "assignees": "Assignees",
    "createFailed": "Milestone creation failed",
    "deleteFailed": "Milestone delete failed",
    "deletedSuccess": "Milestone deleted successfully"
  },
  "greetings": {
    "morning": "Good Morning",
    "afternoon": "Good Afternoon",
    "evening": "Good Evening"
  },
  "profile": {
    "company_website": "Company website",
    "company_name": "Company name",
    "newEmail": "Enter New Email Address",
    "confirmPassword": "Confirm Password",
    "currentPassword": "Current Password",
    "newPassword": "New Password",
    "newPasswordConfirm": "Confirm New Password",
    "create": "Create Profile",
    "fullName": "Full name",
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email",
    "companyName": "Company name",
    "contactName": "Contact name",
    "phone": "Phone",
    "taxNumber": "Tax number",
    "companySite": "Company site",
    "notes": "Notes",
    "index": "Profile",
    "referrals": "Referrals",
    "edit": "Edit",
    "referralLink": "Referral link",
    "copyLink": "Copy link",
    "profileDetails": "Profile details",
    "profilePicture": "Profile picture",
    "profilePictureDesc": "Allowed file types: png, jpg, jpeg.",
    "signInMethod": "Sign in method",
    "deactivateAccount": "Deactivate account",
    "deactivateAccountNoticeTitle": "Attention!",
    "deactivateAccountNoticeDesc": "Your data will be permanently deleted from our system. This process cannot be reversed.",
    "deactivateAccountConfirmCheckBox": "I confirm my account deactivation",
    "goToProfile": "Go to profile",
    "qrcode": "QR code",
    "hideReader": "Cancel",
    "showReader": "Read QR code",
    "areYouSureYouWantToDelete": "If you are sure you want to delete it, click the Save button.",
    "saved": "Changes saved successfully",
    "saving": "Saving...",
    "api_keys": "API keys",
    "generate_api_key" : "Generate API Key",
    "generate_api_key_if_not_owner" : "Generate API Key",
    "generate" : "Generate",
    "date" : "Date",
    "user" : "User",
    "api_key" : "API Key",
    "tenant" : "Company ID",
    "tenant_poppover" : "Company ID is a unique identifier for your company. You can use it to identify your company in the API.",
    "copy" : "Copy",
    "open_document_page": "Open document page",
    "open_document_page_info": "InnoLeads API documentation"
  },
  "general": {
    "save": "Save",
    "show": "Show",
    "cancel": "Cancel",
    "update": "Update",
    "change": "Change",
    "delete": "Delete",
    "edit": "Edit",
    "add": "Add",
    "back": "Back",
    "next": "Next",
    "previous": "Previous",
    "remove": "Remove",
    "search": "Search",
    "searchPlaceholder": "Search...",
    "discard": "Discard",
    "areYouSure": "Are you sure?",
    "yes": "Yes",
    "no": "No",
    "deleted": "Deleted",
    "title": "Title",
    "description": "Description",
    "total": "Total",
    "choose": "Choose",
    "details": "Details",
    "completed": "Completed",
    "can_not_delete_yourself": "You can not delete yourself",
    "noData": "No data to display"
  },
  "menu": {
    "title": "Menu",
    "dashboard": "Dashboard",
    "sales": "Sales",
    "leads": "Leads",
    "leadTemplates": "Lead Templates",
    "todos": "Todos",
    "administration": "Administration",
    "appManagement": "App Management"
  },
  "partners": {
    "createSuccess": "Partner created successfully",
    "updateSuccess": "Partner updated successfully",
    "deleteSuccess": "Partner deleted successfully",
    "createFailed": "Partner creation failed",
    "updateFailed": "Partner update failed",
    "deleteFailed": "Partner delete failed",
    "title": "Partners",
    "name": "Name",
    "email": "Email",
    "netValue": "Net Value",
    "last_contacted_at": "Last contacted at",
    "contact_channel": "Contact channel",
    "create": "Create Partner",
    "edit": "Edit Partner",
    "fullName": "Full name",
    "firstName": "First name",
    "lastName": "Last name",
    "companyName": "Company name",
    "phone": "Phone",
    "taxNumber": "Tax number",
    "contactName": "Contact name",
    "companySite": "Company site",
    "notes": "Notes",
    "index": "Partners",
    "firstNameRequired": "First name is required",
    "lastNameRequired": "Last name is required",
    "addPartner": "Add Partner",
    "import": "Import",
    "export": "Export",
    "file": "Excel file (xlsx)",
    "import_success": "Partners imported successfully",
    "created_by": "Created by"
  },
  "company": {
    "details": "Company details",
    "plan": "Plan",
    "billingHistory": "Billing history",
    "permissions": "Permissions",
    "activeSubscription": "Active subscription",
    "success": "Company features updated successfully"
  },
  "list": "List",
  "partnerManagement": "Partner management",
  "companies": "Companies",
  "create": "Create",
  "search": "Search",
  "actions": "Actions",
  "notification": "Notification",
  "help": "Help",
  "created_at": "Created at",
  "nameRequired": "Name is required.",
  "titleRequired": "Title is required.",
  "emailRequired": "Email is required.",
  "fileRequired": "File is required.",
  "companyNameRequired": "Company name is required.",
  "taxNumberRequired": "Tax number is required.",
  "privacyPolicyRequired": "Privacy policy is required.",
  "termsAndConditionsRequired": "Terms and conditions are required.",
  "passwordRequired": "Password is required.",
  "passwordConfirmationRequired": "Password confirmation is required.",
  "passwordMinLength": "Password must be at least 8 characters.",
  "passwordMatch": "Passwords must match.",
  "forgotPassword": "Forgot password?",
  "notAMember": "Not a Member yet??",
  "alreadyHaveAnAccount": "Already have an account?",
  "signIn": "Sign in",
  "resetPassword": "Forgot Password",
  "myProfile": "Profile",
  "myProjects": "Projects",
  "mySubscription": "Subscription",
  "language": "Language",
  "accountSettings": "Account Settings",
  "logout": "Logout",
  "crm": "CRM",
  "signUp": "Register",
  "companyName": "Company Name",
  "taxNumber": "Tax Number",
  "name": "Name",
  "email": "Email",
  "password": "Password",
  "contactName": "Contact Name",
  "pleaseWait": "Please wait...",
  "privacyPolicy1": "I have read the ",
  "privacyPolicy2": "privacy policy",
  "privacyPolicy3": ", I have acknowledged it, I understand and accept it.*",
  "terms1": "I have read the ",
  "terms2": "terms of use",
  "terms3": ", I have acknowledged it, I understand and accept it.*",
  "passwordHint": "Use 8 or more characters with a mix of letters, numbers &amp; symbols.",
  "hungarian": "Hungarian",
  "english": "English",
  "subscription_handling": "Subscription handling",
  "cancel_at": "Subscription ends at",
  "status": "Status",
  "restorePassword": "Restore Password",
  "backToLogin": "Back to login",
  "probability_percentRequired": "Probability percent is required.",
  "phoneRequired": "Phone is required.",
  "phone": "Phone",
  "profile_picture": "Profile picture"
}
